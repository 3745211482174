<template>
  <div>
    <breadcrumb-bar
      :title="appTitle"
      :breadcrumbs="breadcrumbs"
    />
    <v-container
      fluid
    >
      <v-card>
        <v-card-title>
          <div class="display-2 mb-4">
            Filter Options
          </div>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-row>
              <v-col
                cols="3"
              >
                <v-select
                  v-model="filterData.transactionType"
                  :items="transactionTypes"
                  item-text="value"
                  item-value="key"
                  :label="$t('historyFilterTransactionType')"
                />
              </v-col>
              <v-col
                cols="3"
              >
                <v-select
                  v-model="filterData.partnerId"
                  :items="partners"
                  item-text="name"
                  item-value="partnerId"
                  :label="$t('historyFilterPartner')"
                />
              </v-col>
              <v-col
                cols="2"
              >
                <v-text-field
                  v-model="filterData.startDate"
                  type="Date"
                  :label="$t('historyFilterStartDate')"
                />
              </v-col>
              <v-col
                cols="2"
              >
                <v-text-field
                  v-model="filterData.endDate"
                  type="Date"
                  :label="$t('historyFilterEndDate')"
                />
              </v-col>
              <!--
              <v-col
                cols="2"
              >
                <v-select
                  v-model="filterData.status"
                  :label="$t('historyFilterStatus')"
                />
              </v-col>
              -->
              <v-col
                cols="1"
              >
                <v-btn
                  color="grey darken-2"
                  @click="onButtonSearch"
                >
                  Search
                </v-btn>
              </v-col>
              <v-col
                cols="1"
              >
                <v-btn
                  color="grey lighten-1"
                  @click="onButtonClear"
                >
                  Clear
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
      <v-card>
        <v-card-text>
          <v-data-table
            :headers="transactionHeaders"
            :items="transactions"
            item-key="transactionId"
            hide-default-footer
            single-select
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar
                flat
                color="black"
              >
                <v-toolbar-title
                  class="white--text"
                >
                  {{ $t('titleMaterialMovementRecent') }}
                </v-toolbar-title>
                <v-spacer />
                <download-excel
                  :data="transactions"
                  name="Transactions"
                >
                  <v-btn
                    text
                    class="white--text"
                  >
                    <v-icon>
                      mdi-download
                    </v-icon>
                    Download
                  </v-btn>
                </download-excel>
              </v-toolbar>
            </template>
            <template v-slot:item.transactionDate="{ item }">
              <div>
                {{ item.transactionDate | moment('LL') }}
              </div>
            </template>
            <template v-slot:item.originQuantity="{ item }">
              <div>
                {{ Number(item.originQuantity).toFixed(2) }} {{ item.unitofMeasureText }}
              </div>
            </template>
            <template v-slot:item.originRQuantity="{ item }">
              <div>
                {{ Number(item.originRQuantity).toFixed(2) }} {{ item.unitofMeasureText }}
              </div>
            </template>
            <template v-slot:item.targetQuantity="{ item }">
              <div>
                {{ Number(item.targetQuantity).toFixed(2) }} {{ item.unitofMeasureText }}
              </div>
            </template>
            <template v-slot:item.targetRQuantity="{ item }">
              <div>
                {{ Number(item.targetRQuantity).toFixed(2) }} {{ item.unitofMeasureText }}
              </div>
            </template>
            <!--
            <template v-slot:item.actions="{ item }">
              <v-icon
                class="mr-2"
                @click="onEditItem(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                class="mr-2"
                @click="onDeleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            -->
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
    <error-dialog
      :dialog="showErrorDialog"
      :title="$t('errorTitleProcess')"
      :message="errorMessage"
      @update:dialog="showErrorDialog = false"
    />
    <process-overlay
      :processing="modelProcessing"
    />
  </div>
</template>
<script>
  import Vue from 'vue'
  import { mapFields } from 'vuex-map-fields'
  import transactionAPI from '@/services/transaction'
  export default {
    name: 'UserSetting',
    components: {
      BreadcrumbBar: () => import('@/views/components/core/BreadcrumbBar'),
      ErrorDialog: () => import('@/views/components/core/ErrorDialog'),
      ProcessOverlay: () => import('@/views/components/core/Overlay'),
      DownloadExcel: () => import('vue-json-excel'),
    },
    data: function () {
      const startDate = Vue.moment().startOf('year')
      const endDate = Vue.moment().endOf('month')
      return {
        appTitle: this.$t('titleMaterialHistory'),
        breadcrumbs: [
          {
            text: this.$t('bcHome'),
            disabled: false,
            href: '/dashboard',
          },
          {
            text: this.$t('bcUserSetting'),
            disabled: true,
            href: '/mysettings',
          },
        ],
        showErrorDialog: false,
        errorMessage: '',
        environment: process.env.VUE_APP_ENVIRONMENT,
        modelProcessing: false,
        filterData: {
          transactionType: '-1',
          partnerId: '0',
          startDate: Vue.moment(startDate, 'yyyy-MM-dd').format().substring(0, 10),
          endDate: Vue.moment(endDate, 'yyyy-MM-dd').format().substring(0, 10),
          status: '-1',
        },
        transactionHeaders: [
          {
            text: this.$t('col-recent-TransactionId'),
            align: 'end`',
            value: 'transactionId',
            sortable: true,
            class: 'historycol display-1',
            width: '5%',
          },
          {
            text: this.$t('col-recent-TransactionDate'),
            align: 'start',
            value: 'transactionDate',
            sortable: true,
            class: 'historycol display-1',
            width: '10%',
          },
          {
            text: this.$t('col-recent-Type'),
            align: 'start',
            value: 'transactionTypeText',
            sortable: true,
            class: 'historycol display-1',
            width: '10%',
          },
          {
            text: this.$t('col-recent-OriginProduct'),
            align: 'start',
            value: 'originProductName',
            sortable: true,
            class: 'historycol display-1',
          },
          {
            text: this.$t('col-recent-TargetPartner'),
            align: 'start',
            value: 'targetPartnerName',
            sortable: true,
            class: 'historycol display-1',
          },
          {
            text: this.$t('col-recent-OriginQuantity'),
            align: 'end',
            value: 'originQuantity',
            sortable: true,
            class: 'historycol display-1',
            width: '10%',
          },
          /*
          {
            text: this.$t('col-recent-OriginRQuantity'),
            align: 'end',
            value: 'originRQuantity',
            sortable: true,
            class: 'historycol display-1',
            width: '10%',
          },
          */
          {
            text: this.$t('col-recent-TargetQuantity'),
            align: 'end',
            value: 'targetQuantity',
            sortable: true,
            class: 'historycol display-1',
            width: '10%',
          },
          /*
          {
            text: this.$t('col-recent-TargetRQuantity'),
            align: 'end',
            value: 'targetRQuantity',
            sortable: true,
            class: 'historycol display-1',
            width: '10%',
          },
          */
          {
            text: this.$t('col-recent-Status'),
            align: 'start',
            value: 'statusCodeText',
            sortable: true,
            class: 'historycol display-1',
            width: '8%',
          },
          /*
          {
            text: this.$t('col-recent-actions'),
            align: 'center',
            value: 'actions',
            sortable: false,
            class: 'historycol display-1',
          }, */
        ],
        transactions: [],
      }
    },
    computed: {
      ...mapFields({
        currentUser: 'currentUser',
        transactionTypes: 'transactionTypes',
        partners: 'partners',
      }),
    },
    mounted: function () {
      this.modelProcessing = true
      if (this.partners.length === 0) {
        this.$store.dispatch('GET_PARTNERS')
      }
      if (this.transactionTypes.length === 0) {
        this.$store.dispatch('GET_TRANSACTION_TYPES')
      }
      this.modelProcessing = false
    },
    methods: {
      onButtonClear: function () {
        const startDate = Vue.moment().startOf('year')
        const endDate = Vue.moment().endOf('month')
        this.filterData.transactionType = '-1'
        this.filterData.partnerId = '0'
        this.filterData.startDate = Vue.moment(startDate, 'yyyy-MM-dd').format().substring(0, 10)
        this.filterData.endDate = Vue.moment(endDate, 'yyyy-MM-dd').format().substring(0, 10)
        this.filterData.status = '-1'
      },
      onButtonSearch: function () {
        this.modelProcessing = true
        const queryData = []
        if (this.filterData.transactionType !== '-1') {
          const filterType = {
            key: 'Type',
            value: this.filterData.transactionType.toString(),
          }
          queryData.push(filterType)
        }
        if (this.filterData.partnerId !== '0') {
          const filterPartner = {
            key: 'TargetPartnerId',
            value: this.filterData.partnerId.toString(),
          }
          queryData.push(filterPartner)
        }
        const filterStartDate = {
          key: 'StartDate',
          value: this.filterData.startDate,
        }
        queryData.push(filterStartDate)
        const filterEndDate = {
          key: 'EndDate',
          value: this.filterData.endDate,
        }
        queryData.push(filterEndDate)
        if (this.filterData.status !== '-1') {
          const filterStatus = {
            key: 'Status',
            value: this.filterData.status,
          }
          queryData.push(filterStatus)
        }
        transactionAPI.getMaterialMovementHistory(queryData)
          .then(response => {
            this.modelProcessing = false
            if (response.data != null) {
              switch (response.type) {
                case 1:
                  // error
                  this.errorMessage = response.errorMessage
                  this.showErrorDialog = true
                  break
                default:
                  this.transactions = response.data
                  break
              }
            }
          })
          .catch(error => {
            this.errorMessage = error.message
            this.showErrorDialog = true
            this.modelProcessing = false
          })
      },
    },
  }
</script>
